import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

export function WhitespaceValidator(): ValidatorFn {
 return (control: AbstractControl): { [key: string]: any } => {
   let isWhitespace = (control.value || '').trim().length === 0;
   let isValid = !isWhitespace;
   return isValid ? null : { whitespace: true };
 };
}

@Directive({
 selector: '[whitespace]',
 providers: [{ provide: NG_VALIDATORS, useExisting: WhitespaceValidatorDirective, multi: true }]
})
export class WhitespaceValidatorDirective {
 private valFn = WhitespaceValidator();
 validate(control: AbstractControl): { [key: string]: any } {
   return this.valFn(control);
 }
}
