import { Auth } from 'aws-amplify';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AdminDetailsService } from './services/admin-details.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private adminDetailsService: AdminDetailsService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      Auth.currentUserInfo().then( userInfo => {
        if(userInfo !== null ) {
           this.adminDetailsService.setLoginCredentials();
           userInfo.username !== null ? this.router.navigateByUrl('/dashboard') : this.router.navigateByUrl('/login');          
        }
      });

    });
  }
}
