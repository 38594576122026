import { AdminDetailsService } from './admin-details.service';
import { Auth } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { DynamodbService }  from './dynamodb.service';

declare var require: any;
const aws_exports = require("../../aws-exports").default;


@Injectable({
  providedIn: 'root'
})
export class HospitalService {
  public hospitals: any = [];
  public totalHospitals: any = [];
  private userTable: string = aws_exports.aws_dynamodb_table_schemas[0].tableName;
  constructor(
    public db:DynamodbService,
    private adminDetailsService: AdminDetailsService,
  ) { 

  }
  async fetchHospitals() {
    const params = {
      TableName: this.userTable,
      FilterExpression: "userType = :ust",
      ExpressionAttributeValues: { ":ust": 'hospital' }
    };
    try {
      let client = await this.db.getDocumentClient();
      let clientNw = client.scan(params).promise();
      await clientNw
        .then( data => {
          this.totalHospitals = [...data.Items]
          const hospitals = [];
          for (const d of data.Items) {
            if(d.isDeleted === undefined || d.isDeleted === false) {
              hospitals.push(d);              
            }
          }
          this.hospitals = hospitals;
          console.log(this.hospitals);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getHospitals() {
    return this.hospitals;
  }

  async editHospital(data:any) {
    const admin = this.adminDetailsService.userDetails();
    const hospitalPin = data.pin;
    const hospitalName = data.name;
    const params = {
      TableName: this.userTable,
      Key: { userId: data.userId },
      UpdateExpression: " set #Name = :name, #Add = :add, #Ph = :ph, #Email = :email",
      ExpressionAttributeNames: { "#Add": "address", "#Ph": "phone", "#Email": "email", "#Name": "name" },
      ExpressionAttributeValues: {
        ":add": data.address, 
        ":ph": data.phone,
        ":email": data.email,
        ":name": data.name,
      },
      ReturnValues: "ALL_NEW"
    };
    try {
      let client = await this.db.getDocumentClient();
      let clientNw = client.update(params).promise();
      return await clientNw
        .then( data => {
          return Auth.signIn(hospitalPin,'Password@123')
          .then( user => {
            return Auth.updateUserAttributes(user, {
              'custom:userName': hospitalName
            }).then( () => {
              Auth.signOut();
              console.log('sign Out');
              // Auth.signIn('admin','Password@123');
              console.log(admin.username,admin.password);
              Auth.signIn(admin.username, admin.password);
              return data.Attributes;
            })
          }
          )    
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  createHospital(hospitalData:any) {
    const admin = this.adminDetailsService.userDetails();
        return Auth.signUp ({
        username: hospitalData.pin,
        password: 'Password@123',
        attributes: {
            'email': 'royakash@digitalavenues.com', 
            'custom:userName': hospitalData.name        
        },
    })
    .then( () => {
        return Auth.signIn(hospitalData.pin,'Password@123')
        .then( ()=> { 
         return Auth.currentUserInfo().then( async userInfo => {
            const params = {
              TableName: this.userTable,
              Item: {
                'userId' : userInfo.id,
                'name' : hospitalData.name,
                'pin': hospitalData.pin.toString(),
                'email': hospitalData.email,
                'phone': hospitalData.phone,
                'address': hospitalData.address,
                'userType': 'hospital'
              },
              ReturnValues: 'ALL_OLD'
            };
            try {
              let client = await this.db.getDocumentClient();
              let clientNw = client.put(params).promise();
              return await clientNw
                .then( async data => {
                    Auth.signOut();
                    console.log('sign Out');
                    console.log(admin.username,admin.password);
                    // await Auth.signIn('admin','Password@123');
                    await Auth.signIn(admin.username,admin.password);
                    return data.$response;
                })
                .catch(err => {
                  console.log(err);
                });
            } catch (error) {
              console.log(error);
            }
          })
        })
    })
    .catch( error => console.log('error',error));
  }

  async deleteHospital(userId:string) {
    const params = {
      TableName: this.userTable,
      Key: { userId: userId },
      UpdateExpression: " set #isDeleted = :isDeleted",
      ExpressionAttributeNames: { "#isDeleted": "isDeleted" },
      ExpressionAttributeValues: {
        ":isDeleted": true
      },
      ReturnValues: "ALL_NEW"
    };
    try {
      let client = await this.db.getDocumentClient();
      let clientNw = client.update(params).promise();
      return await clientNw
        .then( data => {
          return data;
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }
}
