import { CommonService } from './services/common.service';
import { LoginpageAuthGuardService } from './services/loginpage-auth-guard.service';
import { CryptionService } from './services/cryption.service';
import { HospitalService } from './services/hospital.service';
import { DynamodbService } from './services/dynamodb.service';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule , MatCheckboxModule } from '@angular/material'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AdminDetailsService } from '../app/services/admin-details.service';



import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import Amplify from "aws-amplify";
import { ToastMessageService } from './services/toastMessage.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { AuthGuardService } from './services/auth-guard.service';
import { TeamService } from './services/team.service';
import { WhitespaceValidatorDirective } from './directives/whitespace-validator.directive';



library.add(fas);

declare var require: any;

const aws_exports = require("../aws-exports").default;

Amplify.configure(aws_exports);

@NgModule({
  declarations: [AppComponent, WhitespaceValidatorDirective],
  entryComponents: [],
  imports: [ BrowserModule, 
     IonicModule.forRoot(), 
     AppRoutingModule,
     NgbModule.forRoot(), 
     DataTablesModule, 
     FontAwesomeModule, 
     BrowserAnimationsModule,
     MatTooltipModule,
     MatCheckboxModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ToastMessageService,
    AuthGuardService,
    DynamodbService,
    TeamService,
    HospitalService,
    CryptionService,
    LoginpageAuthGuardService,
    CommonService,
    AdminDetailsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
