import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';


@Injectable({
  providedIn: 'root'
})
export class LoginpageAuthGuardService {

  constructor(
    private router: Router
    ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    let userInfo = await Auth.currentUserInfo();
    if (userInfo) {
      return false;
    }
    else {
      return true;
    }
  }
}
