import { AdminDetailsService } from './admin-details.service';
import { CryptionService } from './cryption.service';
import { Injectable } from '@angular/core';
import { DynamodbService }  from './dynamodb.service';
import { Storage as S3, Auth } from 'aws-amplify';
import { ToastMessageService } from './toastMessage.service';
import { LoadingController } from '@ionic/angular';


declare var require: any;
const aws_exports = require("../../aws-exports").default;

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  public teams: any = [];
  public teamSubTypes: any = [];
  private userTable: string = aws_exports.aws_dynamodb_table_schemas[0].tableName;
  private teamSubType: string = aws_exports.aws_dynamodb_table_schemas[3].tableName;
  constructor(
    public db: DynamodbService,
    public crypt: CryptionService,
    public toastMessage: ToastMessageService,
    private adminDetailsService: AdminDetailsService,
    public loadingCtrl: LoadingController
  ) 
  { 

  }

  async fetchTeams() {
    const params = {
      TableName: this.userTable,
      FilterExpression: "userType = :ust",
      ExpressionAttributeValues: { ":ust": 'team' }
    };
    try {
      let client = await this.db.getDocumentClient();
      let clientNw = client.scan(params).promise();
      await clientNw
        .then( async data => {
          const teams = [];
          for (const team of data.Items) {
            if( team.isDeleted === undefined || team.isDeleted === false) {
              team.img = await this.avatar(team.userId);    
              teams.push(team);
            }
          }
          console.log(teams);
          this.teams = teams;
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async editTeam(data: any) {
    const params = {
      TableName: this.userTable,
      Key: { userId: data.userId },
      UpdateExpression: " set #Fn = :fn, #Ln = :ln, #ust = :ust, #St = :st, #Add = :add, #Roaster = :roaster",
      ExpressionAttributeNames: { "#Fn": "firstName", "#Ln": "lastName", "#ust": "userSubType", "#St": "stadium", "#Add": "address", "#Roaster": "roaster"},
      ExpressionAttributeValues: {
        ":st": data.stadium,
        ":add": data.address, 
        ":roaster": data.roaster,
        ":fn": data.firstName,
        ":ln": data.lastName,
        ":ust": data.userSubType,
      },
      ReturnValues: "ALL_NEW"
    };
    try {
      let client = await this.db.getDocumentClient();
      let clientNw = client.update(params).promise();
      return await clientNw
        .then( data => {
           return data.Attributes;
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getTeams() {
    return this.teams;
  }
  
    avatar(id:string) {
      return  S3.get(`${id}/avatar`)
      .then( img => {
         return img;
      }) 
      .catch( error => console.log(error))
  }

  async createTeam(teamData:any) {
    let loading = await this.loadingCtrl.create({
      message: "Please wait..."
    });
    loading.present();
    const admin = this.adminDetailsService.userDetails();
    return Auth.signUp ({
    username: teamData.username.toUpperCase(),
    password: teamData.temp_password,
        attributes: {
            'email': teamData.email, 
            'custom:userType': 'team',
            'custom:userSubType': teamData.userSubType,
            'custom:firstTimeLogin': 'yes',  
        },
    })
    .then( () => {
         return Auth.signIn(teamData.username.toUpperCase(),teamData.temp_password)
    .then( ()=> { 
        return Auth.currentUserInfo().then( async userInfo => {
            const params = {
              TableName: this.userTable,
              Item: {
                'userId': userInfo.id,
                'firstName': teamData.firstName,
                'lastName': teamData.lastName,
                'temporary_password': teamData.temp_password,
                'userSubType': teamData.userSubType,
                'userType': 'team',
                'stadium': teamData.stadium,
                'address': teamData.address,
                'roaster': teamData.roaster,
                'username': teamData.username,
                'email': teamData.email,
              },
              ReturnValues: 'ALL_OLD'
            };
        try {
          let client = await this.db.getDocumentClient();
          let clientNw = client.put(params).promise();
          return await clientNw
            .then( async data => {
                console.log(data.$response);
                Auth.signOut();
                // await Auth.signIn('admin','Password@123');
                await Auth.signIn(admin.username,admin.password);
                loading.dismiss();
                return data.$response;
            })
            .catch(err => {
              loading.dismiss();
              console.log(err);
            });
        } catch (error) {
          loading.dismiss();
          console.log(error);
        }
      })
    })
  })
  .catch( error => { 
    console.log('error',error);
    if (error.name === "UsernameExistsException") {
      this.toastMessage.displayMessage(
        "The username you entered is already taken. Please enter a different one."
      );
      loading.dismiss();
    } else {
      this.toastMessage.displayMessage(error.message);
      loading.dismiss();
    }
  });
  }
  
  async fetchTeamSubTypeData() {
    const params = {
      TableName: this.teamSubType,
    };
    try {
      let client = await this.db.getDocumentClient();
      let clientNw = client.scan(params).promise();
      await clientNw
        .then( async data => {
          console.log(data.Items);
          this.teamSubTypes = data.Items;
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getTeamSubTypeData() {
    return this.teamSubTypes;
  }

  async deleteTeam(userId:string) {
    const params = {
      TableName: this.userTable,
      Key: { userId: userId },
      UpdateExpression: " set #isDeleted = :isDeleted",
      ExpressionAttributeNames: { "#isDeleted": "isDeleted" },
      ExpressionAttributeValues: {
        ":isDeleted": true
      },
      ReturnValues: "ALL_NEW"
    };
    try {
      let client = await this.db.getDocumentClient();
      let clientNw = client.update(params).promise();
      return await clientNw
        .then( data => {
          return data;
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }
}
