import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { bool } from 'aws-sdk/clients/signer';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private addHospitalPopupSource = new BehaviorSubject(false);
  addHospitalPopup = this.addHospitalPopupSource.asObservable();

  constructor() { }

  toggleAddHospitalPopup(status: bool) {
    this.addHospitalPopupSource.next(status)
  }
}
