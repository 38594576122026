import { Auth } from 'aws-amplify';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminDetailsService {
  public username:string;
  public password:string;

  constructor() { }

  setLoginCredentials(username?:string,password?:string){
    Auth.currentUserInfo()
    .then( user => {
      this.username = user.username;
      this.password = user.attributes['custom:userPassword'];
    })
  }

  resetLoginCredentials() {
    this.username = null;
    this.password = null;
  }

  userDetails() {
    const user =  {
      username: this.username,
      password: this.password,
    }
    return user;
  }
}
