import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable()
export class ToastMessageService {
  constructor(private toastCtrl: ToastController) {}
  //Display Toast Message
  async displayMessage(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      position: "top"
    });

    toast.onDidDismiss().then( ()=> {});

    toast.present();
  }

  async displayErrorMessage(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      position: "top",
      cssClass: 'displayErrorMessage',
    });

    toast.onDidDismiss().then( ()=> {});

    toast.present();
  }


}
