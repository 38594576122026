import { Injectable } from '@angular/core';
import * as crypto from "crypto-js";



import { environment as ENV } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class CryptionService {

  constructor() {}

  encrypt(message: string) {
    try {
      return message
        ? crypto.AES.encrypt(message.trim(), ENV.CRYPT_SECRET).toString()
        : null;
    } catch (error) {
      if (error) {
        return null;
      }
    }
  }

  /**
   * Decryption Data using AES
   */
  decrypt(encryptedMessage: string, messageType?: string) {
    try {
      let messageByte = encryptedMessage
        ? crypto.AES.decrypt(encryptedMessage.trim(), ENV.CRYPT_SECRET)
        : null;
      if (messageType === "array") {
        return messageByte
          ? messageByte.toString(crypto.enc.Utf8).split(",")
          : null;
      } else {
        return messageByte ? messageByte.toString(crypto.enc.Utf8) : null;
      }
    } catch (error) {
      return null;
    }
  }
}
