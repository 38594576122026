// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7f8460c4-cbfb-45d6-953e-e3323c6dbe71",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_qzVoxYAdc",
    "aws_user_pools_web_client_id": "cfv2c6v99hl1l22qlj9pln7b8",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ProdUsers-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "ProdTeamFeeds-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "ProdChildFeeds-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "ProdUserSubType-prod",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "tcm-prod531e30ff51f5416fa0500f85771415c5-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://eui4rumwrjgw7ghofojbrppqhm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;