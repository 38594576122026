import { LoginpageAuthGuardService } from './services/loginpage-auth-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule', canActivate: [LoginpageAuthGuardService]},
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule', canActivate: [AuthGuardService] },
  { path: 'hospitals-list', loadChildren: './hospitals-list/hospitals-list.module#HospitalsListPageModule', canActivate: [AuthGuardService] },
  { path: 'teams-list', loadChildren: './teams-list/teams-list.module#TeamsListPageModule', canActivate: [AuthGuardService] },
  { path: 'hospitals-list', loadChildren: './hospitals-list/hospitals-list.module#HospitalsListPageModule', canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
