import { Injectable } from '@angular/core';
import { Auth, Logger } from "aws-amplify";
import * as AWS from "aws-sdk";
declare var require: any;

const aws_exports = require("../../aws-exports").default;

AWS.config.region = aws_exports.aws_project_region;
AWS.config.update({ customUserAgent: "ionic-starter" });


const logger = new Logger("DynamoDB");



@Injectable({
  providedIn: 'root'
})
export class DynamodbService {

  constructor() { }

  getDocumentClient() {
    return Auth.currentCredentials()
      .then(
        credentials =>
          new AWS.DynamoDB.DocumentClient({ credentials: credentials })
      )
      .catch(err => {
        logger.debug("error getting document client", err);
        throw err;
      });
  }
}
