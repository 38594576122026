import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot) {
    const userInfo = await Auth.currentUserInfo();
    if ( userInfo ) { 
      return true;
    }
    else {
      this.router.navigate(['login']);
      return false;
    }
  }

}
